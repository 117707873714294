import React from "react"
import { Link, graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Products from '../sections/content-products'
import { md } from '../common'

export default ({ data }) => (
  <Layout>
    <SEO title="404: Not found" />
    <div css={`text-align:center;padding:2rem;min-height:30vh;${md}{min-height:100vh;}`}>
    <h1>Oops the Page you landed on has moved - We're So Sorry</h1>
    <p>Feel free to <Link to="/contact-us"> Contact Us</Link> if you need anything specific or browse some of our popular areas</p>
    <Products data={data} title="Underwood's Designs" link="/products/underwoods-designs"/>
    </div>
  </Layout>
)

export const FourOFourQuery = graphql`
  query FourOFourQuery {
    postgres {
      nodeId
      allProductsItems(condition: {designer: "Underwood's Designs"}, first: 3) {
        edges {
          node {
            price
            title
            sku
            image
          }
        }
      }
    }
  }
`
